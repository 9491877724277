<template>
  <v-row justify="end">
    <v-col cols="12">
      <v-textarea
        ref="textareaMessageAdmin"
        auto-grow
        class="textareaAdminMessage"
        :label="$t('titleVue.adminMessage.messageAdmin')"
        v-model="formData.message"
      >
      </v-textarea>
    </v-col>
    <v-col cols="auto">
      <v-btn color="info" v-on:click="updateAdminMessage()">{{
        $t("button.save")
      }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  created() {
    this.getMessageAdmin();
  },
  data() {
    return {
      formData: {
        message: ""
      }
    };
  },
  computed: {
    ...mapState("messageAdmin", ["adminMessage"])
  },
  methods: {
    ...mapActions("messageAdmin", ["getMessageAdmin", "updateMessageAdmin"]),
    updateAdminMessage() {
      this.updateMessageAdmin(this.formData)
        .then(res => {
          this.$root.$snackBar(this.$t("success.message"));
        })
        .catch(res => {
          this.$root.$snackBar(this.$t("errors.message"), {
            color: "error"
          });
        });
    }
  },
  watch: {
    adminMessage(val) {
      this.formData.message = val.message;
    }
  }
};
</script>
<style scoped></style>
